.downloadRadioButton {
  -webkit-appearance: none;
  object-fit: contain;
  border-radius: 23px;
  border: solid 0.5px #00b7b8;
  outline:none;
}


.downloadRadioButton:before {
  content:'';
  display:block;
  width:60%;
  height:60%;
  margin: 20% auto;    
  border-radius:50%;    
}
.downloadRadioButton:checked:before {
  background:#00b7b8;;
}
