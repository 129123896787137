.phone-input.react-tel-input {
    .form-control {
        width: 100%;
        background: transparent;
        height: auto;
        border: none;
        padding-left: 38px;
        border-radius: 12px;
    }

    .flag-dropdown {
        border: none;
        border-radius: 12px 0 0 12px;

        &.open {
            .selected-flag {
                background: #f4f6f8 !important;
            }
            
        }
    }
    .react-tel-input .country-list .country:hover {
        .country-name{
            color: white;
        }
    }
    .react-tel-input .country-list .country.highlight {
        .country-name{
            color: white;
        }
    }

    .selected-flag {

        &:hover,
        &:focus {
            background-color: transparent;
        }

        .arrow {
            border-top-color: #222
        }
    }
}

.dark {
    .phone-input.react-tel-input {
        .flag-dropdown {
            background-color: #0F172A;

            &.open {
                .selected-flag {
                    background: #1a1a1a !important;
                }
            }
        }

        .selected-flag {
            .arrow {
                border-top-color: #eee
            }
        }
    }
    .react-tel-input .country-list .country:hover {
        .country-name{
            color: black;
        }
    }
    .react-tel-input .country-list .country.highlight {
        .country-name{
            color: black;
        }
    }
}