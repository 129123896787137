.pics__header-logo {
    left: 30px;
    z-index: 1;
}
.icon-background-dark {
    padding-top: 9px;
    padding-bottom: 9px;
    padding-left: 16px;
padding-right: 16px;
    border-radius: 10px;
    box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.7),
        -4px -4px 10px 0 rgba(58, 58, 58, 0.3);
}

.grad-ver{
    background: rgb(26,26,26);
    background: linear-gradient(0deg, rgba(26,26,26,1) 0%, rgba(33,103,114,0.2) 20%, rgba(33,103,114,0.2) 80%, rgba(26,26,26,1) 100%);
    
}
.grad-hor{
    background: rgb(26,26,26);
    background: linear-gradient(90deg, rgba(26,26,26,1) 0%, rgba(33,103,114,0.2) 20%, rgba(33,103,114,0.2) 80%, rgba(26,26,26,1) 100%);
    
}