.actions-button {
    width: 100%;
    background:linear-gradient(to bottom,transparent 0,rgba(0,0,0,.7) 100%);
}

/* .actions-button span{
    margin-left: 20px;
} */

.react-images__view--isModal {
    display: flex !important;
    justify-content: center !important;
}

.video-tag {
    width: 56px;
    height: 30px;
    border-radius: 8px;
    background-color: #a64492;
    padding: 5px 7px 0 8px;
    font-size: 14px;
    text-align: center;
    color: #eee;
}

.default-tag {
    width: 70px;
    height: 30px;
    border-radius: 8px;
    background-color: #a64492;
    padding: 5px 7px 0 8px;
    font-size: 14px;
    text-align: center;
    color: #eee;
}

.container-padding {
    padding: 24px 30px;
}

.container-padding-small {
    padding: 8px 10px;
}
