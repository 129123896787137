.pics__header-logo-static {
    top: 0px;
    left: 0px;
}
.pics__header-logo-img {
    max-width: 80px;
    margin: 0 16px 0 0;
}
.pics__header-logo-img-ethereal {
    max-width: 180px;
    margin: 0 16px 0 0;
}
.logo-main {
    box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.7),
        -4px -4px 10px 0 rgba(58, 58, 58, 0.3);
}
