.new-folder-mobile {
    height: 160px;
    width: 110px;
}

.new-folder-box {
    border-radius: 12.5px;
    height: 110px;
    box-shadow: 5px 5px 13px 0 rgba(0, 0, 0, 0.7),
        -5px -5px 13px 0 rgba(58, 58, 58, 0.3);
}
