  #countdown>svg {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    transform: rotateY(-180deg) rotateZ(-90deg);
  }

  #countdown>svg circle {
    stroke-dasharray: 113px;
    stroke-dashoffset: 0px;
    stroke-linecap: round;
    stroke-width: 2px;
    fill: none;
    animation: countdown 10s linear infinite forwards;

  }

  @keyframes countdown {
    from {
      stroke-dashoffset: 0px;
    }

    to {
      stroke-dashoffset: 113px;
    }
  }