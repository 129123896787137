.activeTab {
    color: #00b7b8;
    border-radius: 10px;
    padding-right: 25px;
    padding-left: 25px;
    box-shadow: inset 4px 4px 10px 0 rgba(0, 0, 0, 0.7),
    inset -4px -4px 10px 0 rgba(58, 58, 58, 0.5);
}
.sticky-thc {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 2;
}

.head-anim{
    /* animation: 500ms ease-in-out 0s normal none 1 running fadeInDown; */
    transition: top 200ms ease-in-out 0s;
}