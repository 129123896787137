.existing-folder-mobile {
    height: 160px;
    width: 110px;
}

.existing-folder-box {
    border-radius: 12.5px;
    box-shadow: 5px 5px 13px 0 rgba(0, 0, 0, 0.7),
        -5px -5px 13px 0 rgba(58, 58, 58, 0.3);
    height: 110px;
}

.folder-thumbnail-mobile {
    background: url("../../../assets/images/folder-1-dummy-image.jpg");
    background-size: cover;
}
