.folder-name {
    font-family: Poppins;
}

.photos-count {
    font-family: Poppins;
    color: #aaa;
}

.container-padding-favlist{
    padding: 24px 30px;
}