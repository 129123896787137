.navbar__bg-dark {
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.7),
        -4px -4px 10px 0 rgba(58, 58, 58, 0.3);
}
.navbar__text-dark {
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}
.navbar__text-light {
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}
