.gallery-heading {
    width: 421px;
    height: 43px;
    margin-right: 13px;
    font-family: Poppins;
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}
.text-cyan {
    color: #00b7b8;
}

.text-cyan {
    color: #00b7b8;
}

.bg-slate {
    background-color: #555555;
}

.icon-background-dark {
    width: 46px;
    height: 30px;
    margin: 7px 4px 6px 6px;
    padding: 8px 16px;
    border-radius: 10px;
    box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.7),
        -4px -4px 10px 0 rgba(58, 58, 58, 0.3);
}


.light-bg{
    background-color: rgb(244 246 248 / var(--tw-bg-opacity)) !important;
}
.dark-bg{
    background-color: rgb(26 26 26 / var(--tw-bg-opacity)) !important;
}
