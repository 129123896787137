.text-cyan {
    color: #00b7b8;
}
.oval-shape {
    width: 17rem;
    height: 21.25rem;
    margin: 0 0 22px;
}

.p40px {
    padding: 40px;
}

.retake-button {
    border-radius: 10px;
    box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.7),
        -4px -4px 10px 0 rgba(58, 58, 58, 0.3);
}
