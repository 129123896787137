.rc-slider-handle {
    width: 24px;
    height: 24px;
    margin-top: -8px;
    border-radius: 5px;
    border: 4px solid #00b7b8;
    background-color: #00b7b8;
}

.rc-slider-track {
    height: 8px;
    background-color: #00b7b8;
}

.rc-slider-rail {
    height: 8px;
}

.rc-slider {
    min-width: 80px;
    margin-right: 30px;
    margin-left: 15px;
}

.rc-slider-dot {
    display: none;
}