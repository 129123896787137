.pics__header {
    background: url(../../../../assets/images/couple-pic-2.webp);
    background-size: cover;
}

.pics__logo__container {
    width: 400px;
    height: 186px;
    padding: 42px 49px 104px 40px;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    background-image: linear-gradient(312deg, rgba(154, 154, 154, 0) 69%, rgba(15, 15, 15, 0.62) 32%);
}

.pics__header_container {
    /*max-width: 90vw;
    height: 400px;
    padding: 128px 80vw 50px 5px;
    -webkit-filter: blur(40px);
    filter: blur(40px);*/
    /*background-image: linear-gradient(189deg, rgba(154, 154, 154, 0) 66%,
    rgba(15, 15, 15, 0.7) 27%);*/
    background-image: linear-gradient(to bottom, transparent 0, rgba(0, 0, 0, .7) 100%);
}

.pics__header-content {
    flex: 1;
    /* justify-content: flex-end; */
    /* align-items: flex-start; */
    flex-direction: column;
    font-family: Poppins;
    /*position: absolute;
    bottom: 0;*/
    background-image: linear-gradient(to bottom, transparent 0, rgba(0, 0, 0, .7) 100%);
}

.pics__header-title {
    /* margin: 0 0 2px; */
    font-family: Poppins;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #eeeeee;
}

.pics__header-date {
    /* margin: 2px 0 20px; */
    font-family: Poppins;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fffafa;
}

.pics__header-description {
    margin: 20px 0 0;
    font-family: Poppins;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fffafa;
}

.pincode-input-container{
    display: flex;
}

.bottom__container_gradient {
    background: linear-gradient(180deg,transparent 0,rgba(0,0,0,.75));
}

.right__container_gradient {
    background-image: linear-gradient(270deg,transparent 0,rgba(0,0,0,.7));
}

.full__container_gradient {
    background: rgba(0,0,0,.5);
}