.imageCont{
    position: relative;
    width: 100%;
    display: table;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    box-shadow: 0 4px 6px 0 rgb(0 0 0 / 20%), 0 -1px 3px 0 rgb(0 0 0 / 20%)
}
.frameImage{
    top: 0;
    width: 100%;
    position: absolute;
}
.dot {
  border-radius: 50%;
  display: inline-block;
}

.shadow-dark-input-bottom-right{
  box-shadow: 7px 7px 10px 0 rgba(0, 0, 0, 0.15), -7px -7px 10px 0 rgba(255, 255, 255, 0.8);
}

 /* .reactEasyCrop_CropArea{
    width: 100% !important;
    height: 100% !important;
  } */
  
 /* .reactEasyCrop_CropAreaGrid::before{
    content: none !important;
  }
  .reactEasyCrop_CropAreaGrid::after{
    content: none !important;
  }
  .reactEasyCrop_Contain {
    max-width: 175% !important;
    max-height: 100% !important;
    position: relative !important;
  }
  .reactEasyCrop_CropArea {
    width:100% !important;
    height: 100% !important;
    border: none !important; 
    box-sizing: none !important; 
    box-shadow: none !important; 
    color:none !important; 
  } */